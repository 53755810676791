/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

class Footer extends React.Component {
  render() {
    const now = new Date();
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © {now.getFullYear()}{' '}
              <a
                className="font-weight-bold ml-1"
                href="https://www.7mboots.com.br/"
                rel="noopener noreferrer"
                target="_blank"
              >
                7MBoots
              </a>
              {' '}Sistema de fichas
            </div>
          </Col>

          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink
                  href="https://www.7mboots.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  7MBoots
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="http://blog.7mboots.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Blog
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.evacommerce.com.br"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mr-3"
                >
                  <img
                    style={{ width: 70 }}
                    src={require('assets/img/svg/logo.svg')}
                    alt="logo-evacommerce"
                  />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
