import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
// import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';

import Header from 'components/Header';
import Loading from 'components/Loading';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  Input,
} from 'reactstrap';

import api from 'config/api';
import validatePassword from 'common/validatePassword';

export default function UserProfile() {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [strong, setStrong] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem');
    } else {
      setError('');
    }
  }, [newPassword, confirmPassword]);

  // Verifica a 'força' da senha
  useEffect(() => {
    const { strong, error } = validatePassword(newPassword);
    setStrong(strong);
    if (error) setError(error);
  }, [newPassword, confirmPassword]);

  async function changePassword(e) {
    e.preventDefault();

    if (error) return;

    setLoading(true);
    try {
      const { data } = await api.post('/change-password', {
        oldPassword,
        newPassword,
      });

      Swal.fire('Sucesso', data.message, 'success');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
          'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      <Container className="product-view-container mt--7" fluid>
        {loading && <Loading />}
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <h1>Perfil de Usuário</h1>
                <button
                  className="button-like-link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left" /> Voltar
                </button>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={4}>
                    <h5>Alterar Senha</h5>
                    <Label className="mt-2">Senha Antiga:</Label>
                    <Input
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      type="password"
                      maxLength="24"
                    />
                    <Label className="mt-2">Senha Nova: </Label>
                    <Input
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                      maxLength="24"
                    />
                    <Label className="mt-2">Repetir Senha: </Label>
                    <Input
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password"
                      maxLength="24"
                    />
                    <div className="text-muted font-italic">
                      <small>
                        segurança da senha:{' '}
                        {!strong ? (
                          <span className="text-danger font-weight-700">
                            fraca
                          </span>
                        ) : (
                            <span className="text-success font-weight-700">
                              forte
                            </span>
                          )}
                      </small>
                      <br></br>
                      {error && <small className="error-info">{error}</small>}
                    </div>
                    <Button
                      onClick={(e) => changePassword(e)}
                      color="success"
                      outline
                      className="mt-3 float-right"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
