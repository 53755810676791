import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Button,
} from 'reactstrap';

import Header from 'components/Header';
import Loading from 'components/Loading';
import api from 'config/api';

export default function EditProfile() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [cellphone, setCellphone] = useState('');

  useEffect(() => {
    setName(history.location.state.name);
    setCellphone(history.location.state.cellphone);
  }, []);

  function changeProfile(e) {
    e.preventDefault();

    if (!name || cellphone.length < 15) {
      Swal.fire('Incompleto', 'Verifique os dados e tente novamente', 'info');
      return;
    }

    Swal.fire({
      title: 'Alterar dados pessoais',
      text: 'Confirmar alteração de no e/ou whatsapp?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Alterar',
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.put('/user', { name, cellphone });
          history.push('/admin/user-profile');
        } catch (error) {
          if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
            'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      };
        }
        setLoading(false);
      }
    });
  }
  return (
    <>
      <Header />
      <Container className="product-view-container mt--7" fluid>
        {loading && <Loading />}
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <h1>Perfil</h1>
                <button
                  className="button-like-link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left" /> Voltar
                </button>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={4}>
                    <h5>Perfil</h5>
                    <Label className="mt-2">Nome: </Label>
                    <Input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      type="text"
                      maxLength="100"
                    />
                    <Label className="mt-2">Whatsapp: </Label>
                    <NumberFormat
                      value={cellphone}
                      onChange={(e) => setCellphone(e.target.value)}
                      format="(##) #####-####"
                      customInput={Input}
                      placeholder="Whatsapp"
                      type="text"
                    />
                    <Button
                      onClick={(e) => changeProfile(e)}
                      color="success"
                      outline
                      className="mt-3 float-right"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
