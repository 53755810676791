import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';

import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
} from 'reactstrap';

import Header from 'components/Header';
import Loading from 'components/Loading';
import DatePicker from 'components/DatePicker';
import CustomPagination from 'components/CustomPagination';
import api from 'config/api';
import './styles.scss';

export default function Plug() {
  const [loading, setLoading] = useState(false);
  const [plugs, setPlugs] = useState();
  const history = useHistory();
  const [nameField, setNameField] = useState('entryDate');
  const [orderAsc, setOrderAsc] = useState('DESC');
  const [createdAtMin, setCreatedAtMin] = useState();
  const [createdAtMax, setCreatedAtMax] = useState();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchPlugs();
  }, [nameField, orderAsc, createdAtMin, createdAtMax]);

  async function fetchPlugs() {
    const params = { nameField, orderAsc, createdAtMin, createdAtMax };
    setLoading(true);
    try {
      const { data } = await api.get(`/userPlug`, { params });
      setPlugs(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
            'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      }
    }
    setLoading(false);
  }

  function backPage(value) {
    if (value === 'first') {
      setPage(1);
    } else {
      setPage(page - value);
    }
  }

  function skipPage(value) {
    if (value === 'last') {
      setPage(
        total % limit === 0
          ? parseInt(total / limit)
          : parseInt(total / limit) + 1
      );
    } else {
      setPage(page + value);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Header />
      <Container className="mt--7 plugs-container" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <h1>Minhas fichas</h1>
                <button
                  className="button-like-link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left" /> Voltar
                </button>
              </CardHeader>
              <CardBody>
                <Row className="plugRowDate">
                  <span className="mr-4 formText">Filtrar por data</span>
                  <Row>
                    <DatePicker
                      placeholder="Data inicial"
                      onChange={(e) => setCreatedAtMin(e._d)}
                    />
                    <DatePicker
                      placeholder="Data final"
                      onChange={(e) => setCreatedAtMax(e._d)}
                    />
                  </Row>
                </Row>
                <Row className="plugRow">
                  <FormGroup className="formSelect">
                    <span className="mr-3 formText">Ordenar por </span>
                    <Col className="formOrderCol">
                      <select
                        className="form-control orderSelect"
                        id="plugOrderSelect"
                        value={nameField}
                        onChange={(e) => setNameField(e.target.value)}
                      >
                        <option value="entryDate">Data entrada</option>
                        <option value="shippingDate">Data saída</option>
                      </select>
                    </Col>
                    <Col className="formOrderCol">
                      <select
                        className="form-control orderSelect"
                        id="plugOrderSelect"
                        value={orderAsc}
                        onChange={(e) => setOrderAsc(e.target.value)}
                      >
                        <option value="ASC">Crescente</option>
                        <option value="DESC">Decrescente</option>
                      </select>
                    </Col>
                  </FormGroup>
                </Row>
                <Row>
                  <Table
                    className="d-none d-md-table text-center"
                    responsive
                    striped
                  >
                    <thead>
                      <tr>
                        <th className="TableTh">Nome</th>
                        <th className="TableTh">Tipo</th>
                        <th className="TableTh">Imagem</th>
                        <th className="TableTh">Código Ficha</th>
                        <th className="TableTh">Entrada</th>
                        <th className="TableTh">Saída</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plugs ? (
                        plugs.map((plug) => (
                          <tr key={plug.Plug.name}>
                            <td className="TableTd">{plug.Plug.name}</td>
                            <td className="TableTd">{plug.Plug.tag}</td>
                            <td className="TableTd">
                              <img
                                className="plugImg"
                                src={plug.Plug.urlImageProduct}
                                alt="Imagem do produto"
                              />
                            </td>
                            <td className="TableTd">{plug.idPlug}</td>
                            <td className="TableTd">
                              {moment(plug.entryDate).format('DD/MM/YYYY')}
                            </td>
                            <td className="TableTd">
                              {plug.shippingDate
                                ? moment(plug.shippingDate).format('DD/MM/YYYY')
                                : plug.shippingDate}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan="8">
                            {!loading
                              ? 'Nenhum registro foi encontrado...'
                              : ''}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <ul style={{ listStyleType: 'none' }} className="d-md-none">
                    {(!plugs || plugs.length === 0) && (
                      <li className="mt-3">
                        Nenhum registro foi encontrado...
                      </li>
                    )}
                    <li>
                      {plugs &&
                        plugs.map((plug) => (
                          <div key={plug.Plug.name}>
                            <Row className="plugRow">
                              <Col xs={4} className="plugCol">
                                <img
                                  className="plugImg"
                                  src={plug.Plug.urlImageProduct}
                                  alt="Imagem do produto"
                                />
                              </Col>
                              <Col>
                                <Row>{plug.Plug.name}</Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="font-weight-bold">Tipo</Row>
                                <Row>{plug.Plug.tag}</Row>
                              </Col>
                              <Col>
                                <Row className="font-weight-bold">Código</Row>
                                <Row>{plug.idPlug}</Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="font-weight-bold">Entrada</Row>
                                <Row>
                                  {moment(plug.entryDate).format('DD/MM/YYYY')}
                                </Row>
                              </Col>
                              <Col>
                                <Row className="font-weight-bold">Saída</Row>
                                <Row>
                                  {plug.shippingDate
                                    ? moment(plug.shippingDate).format(
                                        'DD/MM/YYYY'
                                      )
                                    : plug.shippingDate}
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </div>
                        ))}
                    </li>
                  </ul>
                </Row>
                {total > limit && (
                  <div className="d-flex my-3 justify-content-center">
                    <CustomPagination
                      total={total}
                      page={page}
                      length={plugs.length}
                      skipPage={(value) => skipPage(value)}
                      backPage={(value) => backPage(value)}
                      limit={limit}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
