import UserProfile from 'views/admin/UserProfile';
import EditProfile from 'views/admin/UserProfile/EditProfile';
import NotFound from 'views/admin/NotFound';
import Plugs from 'views/admin/Plugs';

var routes = [
  {
    path: '/user-profile',
    name: 'Perfil',
    invisible: true,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/edit-profile',
    name: 'Perfil',
    invisible: true,
    component: EditProfile,
    layout: '/admin',
  },
  {
    path: '/plugs',
    name: 'Fichas',
    icon: 'fas fa-ticket-alt',
    component: Plugs,
    layout: '/admin',
  },
  {
    path: '*',
    invisible: true,
    component: NotFound,
    layout: '/admin',
  },
];
export default routes;
