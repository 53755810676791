export default function auth() {
  const token = localStorage.getItem('token');
  const idAccessLevel = localStorage.getItem('idAccessLevel');

  if (!token || idAccessLevel.toString() !== '3') {
    document.querySelector('body').className = '';
    return false;
  }
  document.querySelector('body').className = 'logged-in';
  return true;
}
