/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';

import Loading from 'components/Loading';
import api from 'config/api';

import '../../styles.scss';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  async function submit() {
    if (!email || !password) {
      setError('Preencha o email e senha por favor!');
      return;
    }

    // valida o e-mail
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(String(email).toLowerCase())) {
      setError('Formato de e-mail inválido!');
      return;
    }

    setLoading(true);
    try {
      const { data } = await api.post('authenticate', { email, password });
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('name', data.data.name);
      localStorage.setItem('idAccessLevel', data.data.idAccessLevel);

      history.push('/admin/plugs');
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
            'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      }
    }
    setLoading(false);
  }

  return (
    <div className="div-container login-container mb-5">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 card-header">
            <img
              src={require('assets/img/brand/logo.png')}
              alt="logo"
              className="login-logo"
            />
          </CardHeader>
          <h1 className="text-center">7MBoots - Sistema de fichas</h1>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Credenciais</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    maxLength="100"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) submit();
                    }}
                    maxLength="24"
                  />
                </InputGroup>
              </FormGroup>
              {error && <small className="error-info">{error}</small>}
              <div className="text-center">
                <Button
                  onClick={() => submit()}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Acessar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link to="/forgot-password">
              <small>Esqueceu sua senha?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
